<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<OrderNav></OrderNav>
			</div>
			<div class="admin-col whole_000 flex_bd">
				<div class="order_box">
					<div class="order-top whole_000">
						<div class="or-top-b">{{$t('cxtit2')}}</div>
					</div>
					<div class="order-top order-body whole_fff">
						<div class="order-table orf_table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="title" :label="$t('cxth1')">
									<template slot-scope="scope">
										<div class="mining-cell flex flex-ac">
											<img :src="scope.row.image" alt="">
											<div class="flex_bd">
												{{scope.row.title}}
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="miningUnit" :label="$t('cxkth1')">
								</el-table-column>
								<el-table-column prop="type" :label="$t('cxkth2')">
									<template slot-scope="scope">
										<span v-if="scope.row.type == 0">{{$t('redTxt7')}}</span>
										<span v-if="scope.row.type == 1">{{$t('redTxt8')}}</span>
										<span v-if="scope.row.type == 2">{{$t('redTxt9')}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="miningDays" :label="$t('cxkth3')">
									<template slot-scope="scope">
										<span
											v-if="scope.row.type == 0">{{scope.row.miningDays}}{{$t('redTxt7')}}</span>
										<span
											v-if="scope.row.type == 1">{{scope.row.miningDays}}{{$t('redTxt8')}}</span>
										<span
											v-if="scope.row.type == 2">{{scope.row.miningDays}}{{$t('redTxt9')}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="miningedDays" :label="$t('cxkth4')">
									<template slot-scope="scope">
										<span
											v-if="scope.row.type == 0">{{scope.row.miningedDays}}{{$t('redTxt7')}}</span>
										<span
											v-if="scope.row.type == 1">{{scope.row.miningedDays}}{{$t('redTxt8')}}</span>
										<span
											v-if="scope.row.type == 2">{{scope.row.miningedDays}}{{$t('redTxt9')}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="miningDaysprofit" :label="$t('cxkth5')">
									<template slot-scope="scope">
										<span v-if="scope.row.type == 0">{{scope.row.miningDaysprofit}}
											{{scope.row.miningUnit}}/{{$t('redTxt7')}}</span>
										<span v-if="scope.row.type == 1">{{scope.row.miningDaysprofit}}
											{{scope.row.miningUnit}}/{{$t('redTxt8')}}</span>
										<span v-if="scope.row.type == 2">{{scope.row.miningDaysprofit}}
											{{scope.row.miningUnit}}/{{$t('redTxt9')}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="currentDaysprofit" :label="$t('cxkth6')">
									<template slot-scope="scope">
										<span v-if="scope.row.type == 0">{{scope.row.currentDaysprofit}}
											{{scope.row.miningUnit}}/{{$t('redTxt7')}}</span>
										<span v-if="scope.row.type == 1">{{scope.row.currentDaysprofit}}
											{{scope.row.miningUnit}}/{{$t('redTxt8')}}</span>
										<span v-if="scope.row.type == 2">{{scope.row.currentDaysprofit}}
											{{scope.row.miningUnit}}/{{$t('redTxt9')}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="miningStatus" :label="$t('cxth6')">
									<template slot-scope="scope">
										<div v-if="scope.row.miningStatus == 0">
											{{$t('cxstate1')}}
										</div>
										<div v-if="scope.row.miningStatus == 1">
											{{$t('cxstate2')}}
										</div>
										<div v-if="scope.row.miningStatus == 2">
											{{$t('cxstate5')}}
										</div>
									</template>
								</el-table-column>
								<template slot="empty">
									<img src="../../assets/photo/exte1.png" alt="">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
						</div>
						<div class="exte-box">
							<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
								layout="prev, pager, next" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		getmyminings
	} from '@/api/api/active'
	import OrderNav from '@/components/OrderNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			OrderNav,
			Foot
		},
		data() {
			return {
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				getmyminings({
					pageNo: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.data.content
						this.total = res.data.totalElements
						this.pageSize = res.data.size
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			}
		}
	}
</script>
